@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.footer {
  background-color: var(--black-color);
  padding: 50px 0;

  color: var(--bcg-color);
  font-size: 15px;
}
.footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @include media(">=tablet") {
    flex-direction: row-reverse;
    justify-content: center;

    gap: 100px;
  }
}
.borderLine {
  height: 1px;
  width: 80%;
  max-width: 1200px;
  display: none;

  background-color: var(--bcg-color);
  @include media(">=tablet") {
    display: block;
    margin: 40px auto;
  }
}
.contact {
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  .bold {
    font-size: 20px;
    font-weight: 600;
  }

  .contactTile {
    display: flex;
    gap: 20px;
  }
}
.hiddenButton {
  display: none;
  @include media(">=laptop") {
    display: block;
  }
}
.companyData {
  width: 290px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  @include media(">=tablet") {
    align-items: flex-end;
  }

  img {
    height: 30px;
  }
}

.smIcons {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 30px 0;

  background-color: var(--black-color);
  color: var(--bcg-color);
}

.copyrights {
  margin: 0 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;

  font-size: 12px;

  img {
    height: 25px;
  }

  @include media(">=tablet") {
    justify-content: flex-end;
  }
}
