@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.bcgSection {
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/assets/mixer.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  position: absolute;
}
.heading {
  display: flex;
}
.heroTxt {
  margin-bottom: 20px;
  color: var(--bcg-color);

  font-size: 35px;
  font-weight: 400;
  line-height: 1.5;

  text-align: center;

  z-index: 1;

  @include media(">=tablet") {
    font-size: 50px;
    margin-bottom: 50px;
  }
}
.linkBox {
  display: flex;
  gap: 15px;
}
