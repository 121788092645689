@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.productBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @include media(">=tablet") {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
  }
}
.productTile {
  max-width: 350px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.productImg {
  width: 250px;
  border-radius: 10px;
}

.productName {
  font-size: 30px;
}

.description {
  padding: 5px;
  border-left: 2px solid var(--cta-color);
}
