@import "./variables.scss";
@import "./breakpoints.scss";
@import "include-media";
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  background-color: var(--bcg-color);

  font-family: "Space Grotesk", sans-serif;
  color: var(--black-color);

  scroll-behavior: smooth;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 8px;

  @include media(">=tablet") {
    padding: 0 50px;
  }

  @include media(">=desktop") {
    width: 1200px;
    padding: 0;
  }
}

.boldTxt {
  color: var(--cta-color);
  font-weight: 700;
}

a,
.a {
  font-size: 20px;
  font-weight: 500;
  color: inherit;
  text-decoration: unset;

  &:hover {
    cursor: pointer;
    color: inherit;
  }
}

h2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 20px;

  @include media(">=tablet") {
    margin-bottom: 50px;
    font-size: 22px;
  }

  @include media(">=laptop") {
    font-size: 27px;
  }
}

h3 {
  padding-top: 20px;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.5;

  @include media(">=tablet") {
    padding-top: 50px;
    font-size: 40px;
  }
}

h4 {
  font-size: 15px;

  @include media(">=tablet") {
    font-size: 21px;
  }
}
