@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.bcgSection {
  height: fit-content;
  display: flex;

  background-image: url(/assets/unplugged.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  @include media(">=laptop") {
    height: 100vh;
  }
}

.sectionBox {
  color: var(--bcg-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  z-index: 1;
}

.cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);

  position: absolute;
}

.heroTxt {
  margin-top: 30px;
  color: var(--bcg-color);

  font-size: 35px;
  font-weight: 400;
  line-height: 1.5;

  text-align: center;

  @include media(">=tablet") {
    font-size: 50px;
  }
  .ctaTxt {
    color: var(--cta-color);
    font-weight: 600;
  }
}
.specialistDescription {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.aboutSpecialist {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;

  @include media(">=tablet") {
    font-size: 20px;
  }
}
.smIcon {
  font-size: 30px;
  margin: 0 20px;
}
