@import "styles/breakpoints.scss";

.headerSection {
  height: 100vh;
  background-image: url(/assets/guitar.jpg);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  display: flex;
  align-items: center;
}

.heading {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-top: 90px;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--bcg-color);

    z-index: 1;

    @include media(">=tablet") {
      font-size: 50px;
      font-weight: 500;
    }

    @include media(">=laptop") {
      font-size: 70px;
      font-weight: 400;
    }
  }

  .ctaTxt {
    color: var(--cta-color);
    font-size: 35px;
    font-weight: 600;

    @include media(">=tablet") {
      font-size: 50px;
    }

    @include media(">=laptop") {
      font-size: 70px;
    }
  }

  h2 {
    color: var(--bcg-color);
    z-index: 1;
  }
}

.linkBox {
  display: flex;
  gap: 15px;

  @include media(">=tablet") {
    justify-content: start;
    gap: 60px;
  }
}

.cover {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  position: absolute;
  // top: 90px;
}
