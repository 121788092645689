@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.mainButton {
  width: fit-content;
  min-width: 120px;

  padding: 20px;

  border-radius: 5px;

  font-size: 14px;
  font-weight: 400;
  text-align: center;

  z-index: 1;

  transition: 0.4s;

  @include media(">=mobile") {
    min-width: 160px;
    padding: 25px;
    font-size: 16px;
  }

  @include media(">=tablet") {
    font-size: 18px;
  }
}
.primeButton {
  border: 3px solid var(--bcg-color);
  background-color: transparent;
  color: var(--bcg-color);

  @include media(">=tablet") {
    &:hover {
      background-color: var(--cta-color);
      border: 3px solid var(--cta-color);
      color: var(--bcg-color);
    }
  }
}

.ctaButton {
  border: 3px solid var(--bcg-color);
  background-color: var(--cta-color);
  color: var(--bcg-color);

  @include media(">=tablet") {
    &:hover {
      background-color: var(--bcg-color);
      border: 3px solid var(--cta-color);
      color: var(--cta-color);
    }
  }
}
