@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "include-media";

.tile {
  width: 300px;
  height: 675px;

  margin: 20px 0;

  display: flex;
  flex-direction: column;

  border-radius: 5px;
  box-shadow: 0 24px 34px 0 rgba(0, 0, 0, 0.2);

  @include media(">=mobile") {
    width: 330px;
  }

  @include media(">=tablet") {
    margin: 50px 0;
  }
}

.tileImg {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  border-radius: 5px;
}

.tileTitle {
  margin: 25px 20px 20px 20px;
  padding: 20px;

  border-left: 5px solid var(--cta-color);

  font-size: 25px;
  font-weight: 500;

  @include media(">=tablet") {
    font-size: 30px;
  }
}

.tileText {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 0 40px;
}

.shopLink {
  padding: 30px 40px;
  font-size: 14px;
  color: var(--cta-color);
}
